import config from '../config'

import DateTime from './DateTime.js'

export default {
    mixins: [DateTime],
    data() {
        return {
            orderStatuses: [
                {
                    text: 'Новый',
                    color: '#9c27b0', // purple
                    icon: 'mdi-flash',
                },
                {
                    text: 'Ожидание курьера',
                    color: '#ff9800', // orange
                    icon: 'mdi-run-fast',
                },
                {
                    text: 'В пути к гостю',
                    color: '#2196f3', // blue
                    icon: 'mdi-cube-send',
                },
                {
                    text: 'Доставлен',
                    color: '#4caf50', // green
                    icon: 'mdi-check-bold',
                },
                {
                    text: 'Создается',
                    color: '#607d8b', // blue-grey
                    icon: 'mdi-account-question',
                },
                {
                    text: 'Назначен',
                    color: '#f44336', // red
                    icon: 'mdi-arrow-right-bold',
                },
                {
                    text: 'Мультизаказ',
                    color: '#222222', // black
                    icon: 'mdi-view-grid',
                },
            ],

            orderServiceStatuses: [
                {
                    text: 'Новая',
                    color: '#9c27b0', // purple
                    icon: 'mdi-flash',
                    status:0
                },
                {
                    text: 'Выполняется',
                    color: '#2196f3', // blue
                    icon: 'mdi-hammer-screwdriver',
                    status:1
                },
                {
                    text: 'Выполнена',
                    color: '#4caf50', // green
                    icon: 'mdi-check-bold',
                    status:3
                },
                {
                    text: 'Ожидает исполнителя',
                    color: '#F0F', 
                    icon: 'mdi-send-clock',
                    status:7
                },
                {
                    text: 'Передана исполнителю',
                    color: '#f44336', 
                    icon: 'mdi-send-check',
                    status:5
                },
            ],

            OrderNameDisplay: {
                orderNumber: 'orderNumber',
                orderNumberExt: 'orderNumberExt',
                orderTodayNumber: 'orderTodayNumber',
                orderTodayNumberExt: 'orderTodayNumberExt',
                pointOrderNumber: 'pointOrderNumber',
                pointOrderNumberExt: 'pointOrderNumberExt',
                pointOrderTodayNumber: 'pointOrderTodayNumber',
                pointOrderTodayNumberExt: 'pointOrderTodayNumberExt',
                extNumber: 'extNumber'
            },
    
            OrderSource: {
                test: 'test',
                telegram: 'telegram',
                iikoTransport: 'iikoTransport',
                iikoBiz: 'iikoBiz',
                mail: 'mail',
                market: 'market',
                web: 'web',
                webhook: 'webhook'
            },

            OrderStatus: {
                new: 'new',
                onWayPoint: 'onWayPoint',
                onWayCustomer: 'onWayCustomer',
                delivered: 'delivered',
                forming: 'forming',
                passed: 'passed',
                pending: 'pending',
                edit: 'edit',
                close: 'close'
            },

            UserNameDisplay: {
                firstName: 'firstName',
                lastName: 'lastName',
                firstLastName: 'firstLastName',
                lastFirstName: 'lastFirstName'
            },
    
            userIsPoint: false,
        }
    },
    created: function() {
        this.userIsPoint = this.$store.state.user.role === config.user.role.point
    },
    methods: {
        orderBodyText(order, service = false) {
            if (!order.start_addr) {
                order.start_addr = '---'
            }
            if (!order.finish_addr) {
                order.finish_addr = '---'
            }

            let res = ``

            if (service) {
                res +=`🔨 Тип услуги: <b>${order.spec}</b><br>`
                res += `🏡 Адрес: <b>${order.finish_addr.replace('\n', ' ')}</b><br>`
            } else {
                if (order.isCombined) {
                    res += `❗️ <b>ВНИМАНИЕ:</b> Объединенный заказ!<br>`

                    const { orders } = order
                    
                    order.pointSequence.forEach((pointId, index) => {
                        const foundedOrder = orders.find(childOrder => childOrder.pointIdUuid === pointId)

                        if (typeof foundedOrder !== 'undefined') {
                            res += `🏪 ${index + 1} точка: <b>${ foundedOrder.start_addr }</b><br>`
                        }
                    })
                } else {
                    res += `🏪 Откуда: <b>${ order.start_addr.replace('\n', ' ') }</b><br>`
                }
    
                res += `🏡 Куда: <b>${order.finish_addr.replace('\n', ' ')}</b><br>`
                res += `🎫 Гость: <b>${order.guest.replace('\n', ' ')}</b><br>`
            }

            if (!service) res += `🧾 Способ оплаты: <b>${order.pay?.replace('\n', ' ')}</b><br>`

            if (order.isCombined) {
                res += `💵 Сумма заказа: <b>${order.totalCost.toString().replace('\n', ' ')}</b><br>`

                let preTime = 'к'
                if (order.mode && order.mode.split(' ')[0] == '🔥') {
                    preTime = 'до'
                }
                res += `${ order.mode }: забрать <b>до ${ this.timeToString(order.date_ready) }</b>, доставить <b>${ preTime } ${ this.timeToString(order.date_delivery) }</b><br>`
            } else {
                res += `💵 Сумма заказа: <b>${order.pay_cost.toString().replace('\n', ' ')}</b><br>`
                if (service) {
                    res += `${ order.mode }: выполнить <b>до ${ this.timeToString(order.date_delivery) }</b><br>`
                } else {
                    let preTime = 'к'
                    if (order.mode && order.mode.split(' ')[0] == '🔥') {
                        preTime = 'до'
                    }
                    res += `${ order.mode }: забрать <b>до ${ this.timeToString(order.date_ready) }</b>, доставить <b>${ preTime } ${ this.timeToString(order.date_delivery) }</b><br>`
                }
            }
            
            const descr = service ? 'Описание:' : 'Комментарий'

            if (order.isCombined) {
                const { orders } = order

                orders.forEach(childOrder => {
                    res += childOrder.descr === '' ? '' : `💬 ${descr} (${childOrder.start_addr}) <b>${childOrder.descr.replace('\n', ' ')}</b><br>`
                })
            } else {
                res += order.descr == '' ? '' : `💬 ${descr}: <b>${order.descr.replace('\n', ' ')}</b><br>`
            }

            res += `🕓 Создан: <b>${this.timeToString(order.date)}</b><br>`
            return res
        },

        orderFullText(order, service = false) {
            let text = ''
            let executorText = ''

            if (service) {
                const name = this.userIsPoint ? order.worker_name.split(' ')[0] : order.worker_name
                executorText += `🛠 Мастер: <b>${name}</b><br>`
            } else {
                const name = this.userIsPoint ? order.courier_name.split(' ')[0] : order.courier_name
                executorText += `🛵 Курьер: <b>${name}</b><br>`
            }

            text += (service ? '🛠' : '📦')+` <b>${order.name}</b> <span class="order-id">${order.id}</span><br>`
            text += this.orderBodyText(order,service)

            if ((order.status == config.order.status.complete || order.status == config.order.status.way_point || order.status == config.order.status.way_guest || order.status == config.order.status.passed) && order.date_accept) {
                text += `🕓 ${service ? 'Принят к исполнению' : 'Отправлен:'} <b>${this.timeToString(order.date_accept)}</b><br>`
            }

            if (!service && (order.status == config.order.status.complete
            ||   order.status == config.order.status.way_point
            ||   order.status == config.order.status.way_guest
            ||   order.status == config.order.status.passed)) {
                if (order.isCombined) {
                    const { orders } = order

                    orders.forEach((childOrder) => {
                        if (childOrder.date_accept && childOrder.date_take) {
                            text += `🚩 Забран в ${childOrder.pointName}: `
                            text += `<b>${ this.timeToString(childOrder.date_take) }</b><br>`
                        }
                    })
                } else {
                    if (order.date_accept && order.date_take) {
                        text += `🚩 Забран: `
                        text += `<b>${ this.timeToString(order.date_take) }</b><br>`
                    }
                }
            }

            if (order.status == config.order.status.complete && order.date_complete) {
                text += `🏁 ${service ? 'Выполнен' : 'Доставлен:'} <b>${this.timeToString(order.date_complete)}</b><br>`
            }
            text += `🔅 Статус: <b>`
            switch (order.status) {
                case config.order.status.new: {
                    text += 'не обработан ❗️'
                    break
                }
                case config.order.status.way_point: {
                    text += service ? 'исполняется ⏳' : 'в пути к заведению 🛵'
                    break
                }
                case config.order.status.way_guest: {
                    text += service ? 'исполняется ⏳' : 'в пути к гостю 🛵'
                    break
                }
                case config.order.status.complete: {
                    text += service ? 'выполнен ✅' : 'доставлен ✅'
                    break
                }
                case config.order.status.forming: {
                    text += 'формируется ⏳'
                    break
                }
                case config.order.status.passed: {
                    text += service ? 'назначен мастеру ⏳' : 'назначен курьеру ⏳'
                    break
                }
            }
            text += `</b><br>`
            if (order.status == config.order.status.complete || order.status == config.order.status.way_point || order.status == config.order.status.way_guest || order.status == config.order.status.passed) {
                text += executorText
            }
            return text
        },

        getOrderMode(order, isShort) {
            let res = ''
        
            if (isShort) {
                res = order.isNearest ? '🔥' : '⏰'
            } else {
                res = order.isNearest ? '🔥 Ближайший' : '⏰ Предзаказ'
            }
        
            return res
        },

        orderBodyText1(order) {
            let addressFrom = '---'
            let addressTo = '---'
            if (order.point.address) {
                addressFrom = order.point.address.replace('\n', ' ')
            }
            if (order.address) {
                addressTo = order.address.replace('\n', ' ')
            }

            let res = ``
            res += `🏪 Откуда: <b>${addressFrom}</b><br>`
            res += `🏡 Куда: <b>${addressTo}</b><br>`
            res += `🎫 Гость: <b>${order.customerString ? order.customerString.replace('\n', ' ') : '--'}</b><br>`
            res += `🧾 Способ оплаты: <b>${order.pay?.replace('\n', ' ')}</b><br>`
            res += `💵 Сумма заказа: <b>${order.payCost.toString().replace('\n', ' ')}</b><br>`
            let preTime = 'к'
            if (order.isNearest) {
                preTime = 'до'
            }

            res += `${ this.getOrderMode(order) }: забрать <b>до ${ this.timeToString(order.dateReady) }</b>, доставить <b>${ preTime } ${ this.timeToString(order.dateDelivery) }</b><br>`
            
            const comment = this.getFullComment(order)
            if (comment !== '') {
                res += `💬 Комментарий: <b>${comment}</b><br>`
            }
            res += `🕓 Создан: <b>${this.timeToString(order.createdAt)}</b><br>`
            return res
        },

        orderFullText1(order, courier) {
            let text = ''

            text += `📦 <b>${this.getOrderName(order)}</b> <span class="order-id">${order.idNum}</span><br>`
            text += this.orderBodyText1(order)

            if (order.dateAccept) {
                text += `🕓 Отправлен: <b>${this.timeToString(order.dateAccept)}</b><br>`

                if (order.dateTake) {
                    text += `🚩 Забран: <b>${this.timeToString(order.dateTake)}</b><br>`

                    if (order.dateComplete) {
                        text += `🏁 Доставлен: <b>${this.timeToString(order.dateComplete)}</b><br>`
                    }
                }
            }

            text += `🔅 Статус: <b>${this.orderStatusText(order)}</b><br>`

            if (order.isDelivery) {
                const courierUser = courier ? courier?.user : order.courier?.user
                if (this.userIsPoint) {
                    if (courierUser) {
                        text += `🛵 Курьер: <b>${this.getUserDisplayName(courierUser, this.UserNameDisplay.firstName)}</b>\n`
                    }
                } else {
                    // * for operator
                    if (order.status === this.OrderStatus.delivered || order.status === this.OrderStatus.onWayPoint || order.status === this.OrderStatus.onWayCustomer || order.status === this.OrderStatus.passed) {
                        if (courierUser) {
                            text += `🛵 Курьер: <b>${this.getUserDisplayName(courierUser, this.UserNameDisplay.firstLastName)}</b>\n`
                        }
                    }
                }
            }

            return text
        },

        // multiOrderBodyText1(multiOrder) {
        //     const { orders, pointSequence } = multiOrder

        //     let res = ``

        //     pointSequence.forEach((e, i) => {
        //         const exactOrder = orders.find(order => order.point.id === e)

        //         if (typeof exactOrder !== 'undefined') res += `
        //             🏪 Адрес #${i + 1} (${exactOrder.point.name})${exactOrder.status === this.OrderStatus.onWayCustomer ? '(забран): ' : ': '} <b>${typeof exactOrder !== 'undefined' ? exactOrder.point.address.replace('\n', ' ') : ''}</b><br>
        //         `
        //     })
            
        //     res += `🏡 Куда: <b>${orders[0].address ? orders[0].address.replace('\n', ' ') : '---'}</b><br>`
        //     res += `🎫 Гость: <b>${orders[0].customerString ? orders[0].customerString.replace('\n', ' ') : '---'}</b><br>`
        //     res += `🧾 Способ оплаты: <b>${multiOrder.orders[0] ? multiOrder.orders[0].pay?.replace('\n', ' ') : '---'}</b><br>`
        //     res += `💵 Сумма заказа: <b>${
        //         multiOrder.totalCost
        //         ? multiOrder.totalCost.toString().replace('\n', ' ') 
        //         : orders.reduce((acc, i) => {
        //             acc += i.payCost
        //             return acc
        //         }, 0)
        //     }</b><br>`

        //     let preTime = 'к'
        //     if (orders[0].isNearest) preTime = 'до'

        //     res += `${ this.getOrderMode(orders[0]) }: забрать <b>до ${ this.timeToString(multiOrder.dateReady) }</b>, доставить <b>${ preTime } ${ this.timeToString(multiOrder.dateDelivery) }</b><br>`

        //     orders.forEach((e => {
        //         const comment = this.getFullComment(e)

        //         if (comment !== '') {
        //             res += `💬 Комментарий (${e.point.name}): <b>${comment}</b><br>`
        //         }
        //     }))

        //     res += `🕓 Создан: <b>${this.timeToString(multiOrder.createdAt)}</b><br>`
        //     return res
        // },

        // multiOrderFullText1(multiOrder, courier) {
        //     const { orders } = multiOrder
        //     let text = ''

        //     text += `❗️ <b>ВНИМАНИЕ:</b> Объединенный заказ!<br>`
        //     text += `📦 <b>${orders.map(e => this.getOrderName(e)).join(' / ')}</b> <span class="order-id">${multiOrder.idNum}</span><br>`
        //     text += this.multiOrderBodyText1(multiOrder)

        //     if (multiOrder.dateAccept) {
        //         text += `🕓 Отправлен: <b>${this.timeToString(multiOrder.dateAccept)}</b><br>`

        //         if (multiOrder.dateTake) {
        //             text += `🚩 Забран: <b>${this.timeToString(multiOrder.dateTake)}</b><br>`

        //             if (multiOrder.dateComplete) {
        //                 text += `🏁 Доставлен: <b>${this.timeToString(multiOrder.dateComplete)}</b><br>`
        //             }
        //         }
        //     }

        //     text += `🔅 Статус: <b>${this.orderStatusText(multiOrder)}</b><br>`

        //     if (multiOrder.isDelivery) {
        //         const courierUser = courier ? courier?.user : multiOrder.courier?.user
        //         if (this.userIsPoint) {
        //             if (courierUser) {
        //                 text += `🛵 Курьер: <b>${this.getUserDisplayName(courierUser, this.UserNameDisplay.firstName)}</b>\n`
        //             }
        //         } else {
        //             // * for operator
        //             if (multiOrder.status === this.OrderStatus.delivered || multiOrder.status === this.OrderStatus.onWayPoint || multiOrder.status === this.OrderStatus.onWayCustomer || multiOrder.status === this.OrderStatus.passed) {
        //                 if (courierUser) {
        //                     text += `🛵 Курьер: <b>${this.getUserDisplayName(courierUser, this.UserNameDisplay.firstLastName)}</b>\n`
        //                 }
        //             }
        //         }
        //     }

        //     return text
        // },

        orderContent(order, service = false) {
            let html = this.orderFullText(order, service)
            return html
        },

        orderStatusStyle(order) {
            return {
                backgroundColor: this.orderStatuses[order.status].color,
            }
        },

        orderStatusStyle1(order, isCombined = false) {
            return {
                backgroundColor: this.orderStatusColorHtml1(order.status, isCombined),
            }
        },
        
        serviceOrderStatusStyle(order) {
            let status = this.orderServiceStatuses.find(s=>s.status==order.status)
            return {
                backgroundColor: status!=undefined?status.color:'#999',
            }
        },

        getUserDisplayName(user, display) {
            let name = '--'
        
            const firstName = user.firstName ?? ''
            const lastName = user.lastName ?? ''
        
            if (display === this.UserNameDisplay.firstName) {
                name = firstName
            } else if (display === this.UserNameDisplay.lastName) {
                name = lastName
            } else if (display === this.UserNameDisplay.firstLastName) {
                name = `${firstName} ${lastName}`
            } else if (display === this.UserNameDisplay.lastFirstName) {
                name = `${lastName} ${firstName}`
            } else {
                name = `${lastName} ${firstName}`
            }
        
            return name.trim()
        },

        orderStatusToIndex(order) {
            let index = 0

            switch (order.status) {
                case this.OrderStatus.new:
                    index = 0
                    break

                case this.OrderStatus.onWayPoint:
                    index = 1
                    break

                case this.OrderStatus.onWayCustomer:
                    index = 2
                    break

                case this.OrderStatus.delivered:
                    index = 3
                    break

                case this.OrderStatus.forming:
                    index = 4
                    break

                case this.OrderStatus.passed:
                    index = 5
                    break

                default:
                    break
            }

            return index
        },

        orderStatusIcon1(status) {
            let icon = 'mdi-question'
            switch (status) {
                case this.OrderStatus.new:
                    icon = 'mdi-flash'
                    break
                case this.OrderStatus.onWayPoint:
                    icon = 'mdi-run-fast'
                    break
                case this.OrderStatus.onWayCustomer:
                    icon = 'mdi-run-fast'
                    break
                case this.OrderStatus.delivered:
                    icon = 'mdi-check-bold'
                    break
                case this.OrderStatus.forming:
                    icon = 'mdi-account-question'
                    break
                case this.OrderStatus.passed:
                    icon = 'mdi-arrow-right-bold'
                    break
                case this.OrderStatus.pending:
                    icon = 'mdi-alert-octagon-outline'
                    break
            }
            return icon
        },

        orderStatusText(order) {
            let text = 'неизвестно'

            switch (order.status) {
                case this.OrderStatus.new:
                    text = '❗️ не обработан'
                    break

                case this.OrderStatus.onWayPoint:
                    text = '🛵 в пути к заведению'
                    break

                case this.OrderStatus.onWayCustomer:
                    text = '🛵 в пути к гостю'
                    break

                case this.OrderStatus.delivered:
                    text = '✅ доставлен'
                    break

                case this.OrderStatus.forming:
                    text = '⏳ формируется'
                    break

                case this.OrderStatus.passed:
                    text = '⏳ передан курьеру'
                    break

                default:
                    break
            }

            return text
        },

        getOrderName(order) {
            let orderName = ''
            const orderNumberStr = order.number ? ` ${order.number}` : ''
            const orderNumberDayStr = order.numberDay ? ` ${order.numberDay}` : ''

            if (order.point.orderNameDisplay === this.OrderNameDisplay.pointOrderNumber) {
                orderName += `${order.point.name}${orderNumberStr}`
            } else if (order.point.orderNameDisplay === this.OrderNameDisplay.pointOrderNumberExt) {
                orderName += `${order.point.name}${orderNumberStr}`
            } else if (order.point.orderNameDisplay === this.OrderNameDisplay.pointOrderTodayNumber) {
                orderName += `${order.point.name}${orderNumberDayStr}`
            } else if (order.point.orderNameDisplay === this.OrderNameDisplay.pointOrderTodayNumberExt) {
                orderName += `${order.point.name}${orderNumberDayStr}`
            }

            if (order.source === this.OrderSource.web) {
                orderName += ` (w-${order.idNum})`
            } else if (order.externalNumber) {
                let orderExternalNumber = ''

                if (order.source === this.OrderSource.iikoTransport) {
                    orderExternalNumber = 'i-'
                } else if (order.source === this.OrderSource.iikoBiz) {
                    orderExternalNumber = 'ib-'
                } else if (order.source === this.OrderSource.mail) {
                    orderExternalNumber = 'm-'
                } else if (order.source === this.OrderSource.market) {
                    orderExternalNumber = 'd-'
                } else if (order.source === this.OrderSource.webhook) {
                    orderExternalNumber = 'h-'
                }

                orderExternalNumber += `${order.externalNumber}`

                if (order.point.orderNameDisplay === this.OrderNameDisplay.pointOrderNumberExt || order.point.orderNameDisplay === this.OrderNameDisplay.pointOrderTodayNumberExt) {
                    orderName += ` (${orderExternalNumber})`
                } else if (order.point.orderNameDisplay === this.OrderNameDisplay.extNumber) {
                    orderName = `${orderExternalNumber}`
                }
            } else if (order.point.orderNameDisplay === this.OrderNameDisplay.extNumber) {
                orderName = `${order.number}`
            }

            return orderName
        },

        getFullComment(order) {
            let res = ''
        
            if (order.commentOperator) {
                res += `от оператора: ${order.commentOperator}, `
            }
        
            if (order.commentPoint) {
                res += `от точки: ${order.commentPoint}, `
            }
        
            if (order.commentCourier) {
                res += `от курьера: ${order.commentCourier}, `
            }
        
            if (order.commentCustomer) {
                res += `от гостя: ${order.commentCustomer}, `
            }
        
            return res.substring(0, res.length - 2)
        },

        orderStatusColorHtml1(status, isCombined = false) {
            let color = 'grey'

            if (isCombined) return color = '#222222'

            switch (status) {
                case this.OrderStatus.new:
                    color = '#9C27B0' // purple
                    break
                case this.OrderStatus.onWayPoint:
                    color = '#FF9800' // orange
                    break
                case this.OrderStatus.onWayCustomer:
                    color = '#2196F3' // blue
                    break
                case this.OrderStatus.delivered:
                    color = '#4CAF50' // green
                    break
                case this.OrderStatus.forming:
                    color = '#607D8B' // blue-grey
                    break
                case this.OrderStatus.passed:
                    color = '#F44336' // red
                    break
                case this.OrderStatus.pending:
                    color = '#F0F' // magenta
                    break
            }
            return color
        },

    }
}