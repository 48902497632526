


<template>
    <div class="broadcast-dialog">
        <v-dialog v-model="dialogBroadcast" width="720">
            <v-card :loading="loadingBroadcast">
                <template slot="progress">
                    <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                </template>

                <v-card-title class="headline grey lighten-2">
                    Рассылка в Telegram бот
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogBroadcast = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="py-2 d-flex">

                    <div v-if="broadcasts.length" class="broadcast-list">
                        <div v-for="broadcast in broadcasts" :key="broadcast.id" class="broadcast-item" @click="onClickEdit(broadcast)">
                            <v-row no-gutters>
                                <v-col cols="7" class="d-flex flex-column">
                                    <span class="my-1"><b class="blue-grey--text">Получатели:
                                        </b>{{ getCouriersList(broadcast, 60) }}</span>
                                    <span class="my-1">
                                        <b class="blue-grey--text">Сообщение: </b>
                                        <span
                                            v-html="broadcast.message.length > 90 ? broadcast.message.substr(0, 90) + '...' : broadcast.message">
                                        </span>
                                    </span>
                                    <span class="my-1"><b class="blue-grey--text">Отправить не ранее: </b>{{
                                            broadcast.date_send ? timeToString(broadcast.date_send, true) : '--'
                                    }}</span>
                                </v-col>

                                <v-col cols="5" class="d-flex flex-column pa-1">

                                    <div class="d-flex justify-end align-center">
                                        <v-tooltip
                                            :disabled="!broadcast.settings || !broadcast.settings.msgError || broadcast.status != config.broadcast.status.error"
                                            bottom
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip class="mx-6" small outlined
                                                    :color="broadcastStatusColor(broadcast.status)" v-bind="attrs" v-on="on">
                                                    {{ broadcastStatusText(broadcast.status) }}
                                                    {{ broadcast.status == config.broadcast.status.process ? ` ${broadcast.tid_complete.length} / ${broadcast.tid_recipient.length}` : '' }}
                                                </v-chip>
                                            </template>
                                            <span>{{ broadcast.settings.msgError }}</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="broadcast.status == config.broadcast.status.new" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$event.stopPropagation(); onClickStart(broadcast)"
                                                    v-bind="attrs" v-on="on">
                                                    <v-icon color="blue">mdi-play</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Запустить</span>
                                        </v-tooltip>

                                        <v-tooltip v-else-if="broadcast.status == config.broadcast.status.complete" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$event.stopPropagation(); onClickRestart(broadcast)"
                                                    v-bind="attrs" v-on="on">
                                                    <v-icon color="blue">mdi-replay</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Перезапустить</span>
                                        </v-tooltip>

                                        <v-btn v-else icon disabled>
                                            <v-icon>mdi-progress-helper</v-icon>
                                        </v-btn>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$event.stopPropagation(); onClickDelete(broadcast)"
                                                    v-bind="attrs" v-on="on">
                                                    <v-icon color="red darken-4">mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Удалить</span>
                                        </v-tooltip>
                                    </div>

                                    <v-spacer></v-spacer>

                                    <div class="d-flex justify-end">
                                        <span v-if="broadcast.date_complete"><b class="blue-grey--text">выполнен:</b> {{
                                                timeToString(broadcast.date_complete, true)
                                        }}</span>
                                    </div>

                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <div v-else class="broadcast-empty">
                        <span v-html="emptyContent"></span>
                    </div>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="onClickCreate">
                        <v-icon>mdi-plus</v-icon>Создать
                    </v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogBroadcast = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEdit" width="1100">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ dialogEditHeader }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogEdit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="py-4">
                    <v-row class="d-flex">
                        <v-col cols="7">
                            <h2>Получатели</h2>
                            <p class="my-2"><b>{{ getCouriersList(broadcastCurrent, 120) }}</b></p>
                            <p v-if="createFlag" class="my-0 caption blue-grey--text"><i>Используется список курьеров из
                                    общей таблицы.</i></p>
                            <p v-if="createFlag" class="my-0 caption blue-grey--text"><i>Для коррекции списка выполните
                                    фильтрацию по
                                    параметрам таблицы.</i></p>

                            <v-btn v-if="!createFlag" outlined text class="mx-1 mt-1 keyboard"
                                @click="setRecipientList">
                                ➡️ Обновить список
                            </v-btn>
                        </v-col>

                        <v-col cols="5">
                            <h2>Когда отправить</h2>
                            <p class="my-2"><b>{{ dateSend ? getDateSend() : 'Время не указано' }}</b></p>
                            <v-btn outlined text class="mx-1 mt-1 keyboard" @click="setDateSendNow">
                                ✅ Сейчас
                            </v-btn>
                            <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickDialogDateSend">
                                ⏳ Указать время
                            </v-btn>
                        </v-col>

                        <v-col cols="7">
                            <v-row no-gutters class="d-flex justify-space-between">
                                <h2>Текст сообщения</h2>
                                <v-spacer></v-spacer>
                                <a @click="onClickFormatExample">пример форматированного текста</a>
                                <v-spacer></v-spacer>
                            </v-row>

                            <v-textarea class="mt-3" ref="broadcastEditor" rows="7" solo
                                :label="message.length ? '' : 'Начните вводить текст'" id="message" name="message"
                                type="text" :value="message" hide-details @input="onInputMessage"
                                @change="onChangeMessage" @keydown="onKeydown"></v-textarea>

                            <v-row no-gutters class="d-flex text-center pa-2 mt-3 grey lighten-4">
                                <v-col cols="6">
                                    <span v-text="'<b>Жирный</b>'"></span>
                                </v-col>
                                <v-col cols="4">
                                    <span v-html="'<b>Жирный</b>'"></span>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="help-button" outlined x-small plain text
                                        @mousedown="onClickFormat($event, '<b>', '</b>')">
                                        <v-icon>mdi-format-bold</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="6">
                                    <span v-text="'<i>Курсив</i>'"></span>
                                </v-col>
                                <v-col cols="4">
                                    <span v-html="'<i>Курсив</i>'"></span>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="help-button" outlined x-small plain text
                                        @mousedown="onClickFormat($event, '<i>', '</i>')">
                                        <v-icon>mdi-format-italic</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="6">
                                    <span v-text="'<code>Код</code>'"></span>
                                </v-col>
                                <v-col cols="4">
                                    <span v-html="'<code>Код</code>'"></span>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="help-button" outlined x-small plain text
                                        @mousedown="onClickFormat($event, '<code>', '</code>')">
                                        <v-icon>mdi-code-tags</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="6">
                                    <span v-text="'<u>Подчеркнутый</u>'"></span>
                                </v-col>
                                <v-col cols="4">
                                    <span v-html="'<u>Подчеркнутый</u>'"></span>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="help-button" outlined x-small plain text
                                        @mousedown="onClickFormat($event, '<u>', '</u>')">
                                        <v-icon>mdi-format-underline</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="6">
                                    <span v-text="'<s>Перечеркнутый</s>'"></span>
                                </v-col>
                                <v-col cols="4">
                                    <span v-html="'<s>Перечеркнутый</s>'"></span>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="help-button-small" outlined x-small plain text
                                        @mousedown="onClickFormat($event, '<s>', '</s>')">
                                        <v-icon>mdi-format-strikethrough-variant</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="6">
                                    <span v-text="'<a href=&quot;mysite.ru&quot;>Мой сайт</a>'"></span>
                                </v-col>
                                <v-col cols="4">
                                    <span v-html="'<a href=&quot;mysite.ru&quot;>Мой сайт</a>'"></span>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="help-button" outlined x-small plain text
                                        @mousedown="onClickFormat($event, '<a href=&quot;mysite.ru&quot;>', '</a>')">
                                        <v-icon>mdi-link-variant</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="5">
                            <h2>Результат в Telegram</h2>
                            <div class="broadcast-wrapper">
                                <div class="broadcast-card elevation-3">
                                    <span class="broadcast-message" v-html="getMessageFormat()"></span>
                                    <span class="broadcast-time" v-html="getTimeSend()"></span>
                                </div>
                            </div>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="py-0">

                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>

                        <v-col cols="3">
                            <v-switch v-model="hideFlag" :label="hideFlag ? 'Скрывается' : 'Не скрывается'"></v-switch>
                        </v-col>

                        <v-col cols="2">
                            <v-text-field class="mx-1" :disabled="!hideFlag" label="Через время, сек."
                                id="broadcast-hide" name="broadcast-hide" type="number" :min="10" v-model="hide"
                                hide-details></v-text-field>
                        </v-col>
                    </v-row>

                    <v-btn class="mx-2" color="success" :disabled="message.length ? false : true" @click="onClickSave">Сохранить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogEdit = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDelete" width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Удаление рассылки
                    <v-spacer></v-spacer>
                    <v-btn icon @click="promptDelete = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы действительно хотите удалить эту рассылку?</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="error" text @click="promptDelete = false">Нет</v-btn>
                    <v-btn class="mx-2" color="success" text @click="promptDeleteYes">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDateSend" width="640">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Выбор времени когда отправить
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogDateSend = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="py-4">
                    <v-row class="d-flex">
                        <v-col cols="6">
                            <div class="subheading">
                                Укажите дату
                            </div>
                            <v-date-picker locale="ru-ru" v-model="pickerDateSend" :events="arrayEvents"
                                event-color="green lighten-1"></v-date-picker>
                        </v-col>

                        <v-col cols="6">
                            <div class="subheading">
                                Укажите время
                            </div>
                            <v-time-picker v-model="pickerDateTimeSend" format="24hr"></v-time-picker>
                        </v-col>

                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <span class="mx-4">{{getPickerDateSend()}}</span>
                    <v-btn class="mx-2" color="primary" @click="onClickSelectDateSend">Выбрать</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogDateSend = false">Отменить</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import config from '../../config'
import moment from 'moment-timezone'

moment.locale('ru')

import DateTime from '../../mixins/DateTime.js'
import G from 'glob'

export default {
    name: 'courier-broadcast',

    mixins: [DateTime],
    props: {
        settings: {
            type: Object,
            required: true,
        },
        couriers: {
            type: Array,
        },
        couriersAll: {
            type: Array,
        },
    },
    emits: ['close'],
    watch: {
        dialogBroadcast: function (newValue, oldValue) {
            if (!newValue) {
                this.$emit('close')
            }
        },
        dialogEdit: function (newValue, oldValue) {
            if (!newValue) {
                setTimeout(() => {
                    this.autoUpdateStart()
                }, 500)
                
            }
        },
    },
    data() {
        return {
            dialogEdit: false,
            dialogEditHeader: '',
            dialogBroadcast: true,
            loadingBroadcast: false,
            broadcasts: [],
            promptDelete: false,
            broadcastId: 0,
            config: config,
            autoUpdate: null,
            message: '',
            broadcastCurrent: null,
            dateSend: null,
            dateTimeSend: null,
            pickerDateSend: null,
            pickerDateTimeSend: null,
            dialogDateSend: false,
            arrayEvents: [],
            createFlag: false,
            hide: 0,
            hideFlag: false,
            emptyContent: 'Пожалуйста, подождите. Идет загрузка...',
        }
    },
    created() {

        this.loadingBroadcast = true
        this.dialogBroadcast = true

        this.autoUpdateStart()

    },
    destroyed() {
        this.autoUpdateStop()
    },
    mount() {
    },
    methods: {
        autoUpdateStart() {
            if (!this.autoUpdate) {
                this.broadcastsUpdate()

                this.autoUpdate = setInterval(() => {
                    this.broadcastsUpdate()
                }, 5000)
            }
        },

        autoUpdateStop() {
            if (this.autoUpdate) {
                clearInterval(this.autoUpdate)
                this.autoUpdate = null
            }
        },

        broadcastsUpdate() {
            this.$store
                .dispatch('broadcasts_get')
                .then(res => {
                    this.emptyContent = 'Нет ни одной рассылки, создайте новую нажав кнопку <b>"+ Создать"</b>'
                    if (res.data.success) {
                        // console.log('res.data.broadcasts', res.data.broadcasts)
                        if (this.autoUpdate) {
                            this.broadcasts = res.data.broadcasts
                            this.arrayEvents = []
                            for (const broadcast of this.broadcasts) {
                                this.arrayEvents.push(moment(broadcast.date_send).format('YYYY-MM-DD'))
                            }
                            // console.log('arrayEvents', this.arrayEvents)
                        }
                    } else {
                        console.error('broadcasts_get not success', res.data)
                    }
                    this.loadingBroadcast = false
                })
                .catch(err => {
                    console.error('broadcasts_get ERROR', err)
                    this.loadingBroadcast = false
                })
        },

        onClickFormat(event, tagStart, tagEnd) {
            console.log('onClickFormat', event, tagStart, tagEnd)
            if (event) {
                event.preventDefault()
            }
            const broadcastEditor = this.$refs.broadcastEditor.$el.querySelector('textarea')

            const [start, end] = [broadcastEditor.selectionStart, broadcastEditor.selectionEnd]
            // console.log('start, end', start, end)
            broadcastEditor.setRangeText(tagStart, start, start)
            broadcastEditor.setRangeText(tagEnd, end + tagStart.length, end + tagStart.length)
            broadcastEditor.setSelectionRange(start, end + tagStart.length + tagEnd.length)
            this.message = broadcastEditor.value
        },

        onClickFormatExample() {
            this.message = `<b>Сам жирный</b> 😡
<i>Курсив</i>
<code>код</code>
<u>подчеркнутый</u>
<s>перечеркнутый</s>
<a href="mysite.ru">Мой сайт</a>`
        },

        onClickCreate() {
            this.autoUpdateStop()
            this.dialogEditHeader = 'Создание новой рассылки'
            this.message = '👋 Привет, <b>друзья</b>!'
            this.hide = 10
            this.hideFlag = false
            this.dateSend = moment().format('YYYY-MM-DD')
            this.dateTimeSend = moment().format('HH:mm')
            this.broadcastCurrent = {
                tid_recipient: [],
                settings: {}
            }
            this.setRecipientList()
            this.createFlag = true
            this.dialogEdit = true
        },

        onClickClose() {
            this.$emit('close')
        },

        onClickEdit(item) {
            this.autoUpdateStop()
            this.dialogEditHeader = 'Редактирование рассылки'
            this.message = item.message
            this.hideFlag = item.hide > 0
            this.hide = item.hide < 10 ? 10 : item.hide
            this.dateSend = moment(item.date_send).format('YYYY-MM-DD')
            this.dateTimeSend = moment(item.date_send).format('HH:mm')
            this.broadcastCurrent = JSON.parse(JSON.stringify(item))
            this.createFlag = false
            this.dialogEdit = true
        },

        onClickDialogDateSend() {
            this.pickerDateSend = this.dateSend
            this.pickerDateTimeSend = this.dateTimeSend
            this.dialogDateSend = true
        },

        updateEvents() {
            this.arrayEvents = []
            for (const broadcast of this.broadcasts) {
                if (broadcast.id === this.broadcastCurrent.id) {
                    this.arrayEvents.push(this.dateSend)
                } else {
                    this.arrayEvents.push(moment(broadcast.date_send).format('YYYY-MM-DD'))
                }
            }
        },

        onClickSelectDateSend() {
            this.dateSend = this.pickerDateSend
            this.dateTimeSend = this.pickerDateTimeSend
            this.updateEvents()
            this.dialogDateSend = false
        },

        onKeydown(event) {
            console.log('event', event)
            if (event.ctrlKey) {
                switch (event.key) {
                    case 'b':
                        this.onClickFormat(null, '<b>', '</b>')
                        break;

                    case 'i':
                        this.onClickFormat(null, '<i>', '</i>')
                        break;

                    case 'd':
                        this.onClickFormat(null, '<code>', '</code>')
                        break;

                    case 'u':
                        this.onClickFormat(null, '<u>', '</u>')
                        break;

                    case 's':
                        this.onClickFormat(null, '<s>', '</s>')
                        break;

                    case 'e':
                        this.onClickFormat(null, '<a href="mysite.ru">', '</a>')
                        break;

                    default:
                        break;
                }
            }
        },

        setDateSendNow() {
            this.dateSend = moment().format('YYYY-MM-DD')
            this.dateTimeSend = moment().format('HH:mm')
            this.updateEvents()
        },

        getPickerDateSend() {
            let date
            if (this.pickerDateSend && this.pickerDateTimeSend) {
                date = moment(moment(this.pickerDateSend).format('YYYY-MM-DD') + ' ' + this.pickerDateTimeSend)
            } else {
                date = moment()
            }
            return this.timeToString(date, true)
        },

        getDateSend() {
            const date = moment(moment(this.dateSend).format('YYYY-MM-DD') + ' ' + this.dateTimeSend)
            return this.timeToString(date, true)
        },

        getTimeSend() {
            let date
            if (this.dateSend && this.dateTimeSend) {
                date = moment(moment(this.dateSend).format('YYYY-MM-DD') + ' ' + this.dateTimeSend)
            } else {
                date = moment()
            }
            return date.format('HH:mm')
        },

        setRecipientList() {
            this.broadcastCurrent.settings.couriersName = {}
            this.broadcastCurrent.tid_recipient = []
            for (const courier of this.couriers) {
                this.broadcastCurrent.tid_recipient.push(courier.tid)
                if (courier?.full_name) {
                    this.broadcastCurrent.settings.couriersName[courier.tid] = this.trim(courier.full_name)
                } else {
                    this.broadcastCurrent.settings.couriersName[courier.tid] = courier.tid
                }
            }
        },

        onClickSave() {
            this.broadcastCurrent.message = this.message
            if (this.hide < 10) {
                this.hide = 10
            }
            this.broadcastCurrent.hide = this.hideFlag ? this.hide : 0
            this.broadcastCurrent.status = this.config.broadcast.status.new
            this.broadcastCurrent.date_create = moment()
            this.broadcastCurrent.date_send = moment(moment(this.dateSend).format('YYYY-MM-DD') + ' ' + this.dateTimeSend)
            this.broadcastCurrent.date_complete = null
            this.broadcastCurrent.tid_complete = []
            if (!this.broadcastCurrent.settings.couriersName) {
                this.broadcastCurrent.settings.couriersName = {}
                for (let i = 0; i < this.broadcastCurrent.tid_recipient.length; i += 1) {
                    const tid = this.broadcastCurrent.tid_recipient[i]
                    const courier = this.couriersAll.find(c => c.tid == tid)
                    if (courier?.full_name) {
                        this.broadcastCurrent.settings.couriersName[tid] = this.trim(courier.full_name)
                    } else {
                        this.broadcastCurrent.settings.couriersName[tid] = tid
                    }
                }
            }
            console.log('this.broadcastCurrent', this.broadcastCurrent)
            this.broadcastSave(this.broadcastCurrent)
            this.dialogEdit = false
        },

        onClickDelete(item) {
            this.promptDelete = true
            this.broadcastId = item.id
        },

        onClickStart(item) {
            item.status = config.broadcast.status.start
            item.tid_complete = []
            this.broadcastSave(item)
        },

        onClickRestart(item) {
            item.status = config.broadcast.status.start
            item.tid_complete = []
            this.broadcastSave(item)
        },

        onChangeMessage(value) {
            // console.log('onChangeMessage', value)
        },

        onInputMessage(value) {
            // console.log('onInputMessage', value)
            this.message = value
        },

        getMessageFormat() {
            let res = this.message.replace(/\\n/g, '<br>')
            res = res.replace(/(^| )(\/\w+)( |$)/gm, '<span class="link">$2</span> ')
            return res
        },

        broadcastSave(item) {
            this.loadingBroadcast = true
            this.autoUpdateStop()
            this.$store
                .dispatch('broadcast_set', item)
                .then(res => {
                    if (!res.data.success) {
                        console.error('broadcastUpdate not success', res.data)
                    }
                    this.loadingBroadcast = false
                    this.autoUpdateStart()
                })
                .catch(err => {
                    console.error('broadcastUpdate ERROR', err)
                    this.loadingBroadcast = false
                    this.autoUpdateStart()
                })
        },

        promptDeleteYes() {
            console.log('delete', this.broadcastId)
            this.promptDelete = false
            this.loadingBroadcast = true
            this.$store
                .dispatch('broadcast_delete', this.broadcastId)
                .then(res => {
                    if (res.data.success) {
                        this.broadcastsUpdate()
                    } else {
                        console.error('broadcast_delete not success', res.data)
                    }
                    this.loadingBroadcast = false
                })
                .catch(err => {
                    console.error('broadcast_delete ERROR', err)
                    this.loadingBroadcast = false
                })
        },

        trim(str) {
            let res = str.replace(/^\s+/, '')
            for (var i = res.length - 1; i >= 0; i--) {
                if (/\S/.test(res.charAt(i))) {
                    res = res.substring(0, i + 1)
                    break
                }
            }
            return res
        },

        getCouriersList(broadcast, length) {
            // let res = ''
            // let couriersTid = []
            // let couriersName = []

            // console.log('this.couriersAll length', this.couriersAll.length)

            // if (broadcast) {
            //     for (const tid of broadcast.tid_recipient) {
            //         couriersTid.push(tid)
            //         // const courier = this.couriersAll.find(courier => courier.tid === tid)
            //         // if (courier) {
            //         //     couriersName.push(courier.full_name)
            //         //     couriersName.push(courier.full_name)
            //         // } else {
            //             couriersName.push(tid)
            //         // }
            //     }
            // }

            // let i
            // for (i = 0; i < 20; i += 1) {
            //     if (res.length < length && couriersTid.length > i) {
            //         if (res.length) {
            //             res += ', '
            //         }
            //         res += couriersName[i]
            //     } else {
            //         break
            //     }
            // }
            // if (couriersTid.length > i) {
            //     res += ` +${couriersTid.length - i}`
            // }

            // return res
            return broadcast?.tid_recipient.length + ' пользователей' ?? '--'
        },

        broadcastStatusColor(status) {
            let color = '#607D8B' // blue-grey

            switch (status) {
                case config.broadcast.status.new:
                    color = '#9C27B0' // purple
                    break

                case config.broadcast.status.start:
                    color = '#2196F3' // blue
                    break

                case config.broadcast.status.wait:
                    color = '#2196F3' // blue
                    break

                case config.broadcast.status.process:
                    color = '#3F51B5' // indigo
                    break

                case config.broadcast.status.pause:
                    color = '#009688' // teal
                    break

                case config.broadcast.status.stop:
                    color = '#FF5722' // deep-orange
                    break

                case config.broadcast.status.complete:
                    color = '#4CAF50' // green
                    break

                case config.broadcast.status.error:
                    color = '#F44336' // red
                    break

                default:
                    break
            }

            return color
        },

        broadcastStatusText(status) {
            let text = 'Неизвестно'

            switch (status) {
                case config.broadcast.status.new:
                    text = 'Новый'
                    break

                case config.broadcast.status.start:
                    text = 'Запущен'
                    break

                case config.broadcast.status.wait:
                    text = 'Ожидает'
                    break

                case config.broadcast.status.process:
                    text = 'В процессе'
                    break

                case config.broadcast.status.pause:
                    text = 'На паузе'
                    break

                case config.broadcast.status.stop:
                    text = 'Остановлен'
                    break

                case config.broadcast.status.complete:
                    text = 'Выполнен'
                    break

                case config.broadcast.status.error:
                    text = 'Ошибка'
                    break

                default:
                    break
            }

            return text
        },

    },
}
</script>


<style>
    .broadcast-card b {
        font-weight: 500 !important;
    }

    .broadcast-card a, .broadcast-card .link {
        color: #0084ff;
        text-decoration: none;
        cursor: pointer;
    }

    .broadcast-card .link:hover, .broadcast-card a:hover {
        text-decoration: underline;
    }

    .v-time-picker-title__time .v-picker__title__btn,
    .v-time-picker-title__time span {
        height: 56px;
        font-size: 56px;
    }
</style>

<style lang="stylus" scoped>
    .v-chip
        cursor: pointer

    .broadcast-list
        height: 300px
        width: 100%
        overflow-y: auto
    .broadcast-item
        width: 100%
        border: 1px solid #CFD8DC
        border-radius: 4px
        margin: 8px 0
        padding: 6px 12px
        cursor: pointer
        line-height: 1rem;
    .broadcast-item:hover
        border: 1px solid #03A9F4
        background-color: #f6f6f6
    
    .broadcast-empty
        margin 20px 10px
        font-weight 400
        // font-style italic

    .broadcast-wrapper
        margin-top: 12px !important;
        height 368px
        overflow-y: auto
        background url("../../assets/images/telegram-background.jpg") 100% 100% no-repeat
        // background-size cover
        display: flex;
        justify-content: center;
        align-items: flex-start;

    .broadcast-card
        background-color: #fff
        border-radius: 16px
        border-bottom-left-radius: 2px
        padding: 8px 14px
        margin: 16px 0
        min-height 38px
        color: #111
        font-size .856rem
        line-height 1.2rem
        white-space: break-spaces
        width: 90%
        position: relative;

    .broadcast-time
        color: #a0a0a0;
        position: absolute;
        bottom: 2px;
        right: 12px;

    .help-button-small
        width 40px !important
        height 20px !important
        i
            font-size 18px

    .help-button
        width 40px !important
        height 20px !important
        i
            font-size 20px

</style>
